import {handleRequest} from "./API";
import {AirlinesSearchResponseType} from "../types/Airlines/Airlines.types";

const AIRLINES_API = {
  getAirlinesSearchResults: async (token: string, searchTerm: string) => {
    return await handleRequest<AirlinesSearchResponseType>(
      "get",
      `airlines`,
      token,
      {searchTerm}
    );
  }
}

export default AIRLINES_API;

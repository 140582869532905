import {createAsyncThunk} from "@reduxjs/toolkit";
import {UserTypes} from "../../types/Users/Users.types";
import {setGlobalError} from "../reducers/errorSlice";
import USERS_API from "../../api/USERS_API";

export const loadUsersList = createAsyncThunk<UserTypes[], { token: string }>("users/loadUsers", async ({token}, { rejectWithValue, dispatch}) => {
  try {
    return await USERS_API.getUsers(token);
  } catch (error) {
    dispatch(setGlobalError(error.message));
    return rejectWithValue(error.message);
  }
});

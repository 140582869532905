import {createSlice} from '@reduxjs/toolkit';
import {getAirlinesSearchResults} from "../thunk/airlinesThunks";
import {getAirportsSearchResults} from "../thunk/airportsThunks";
import {createFlight, getFlightList} from "../thunk/flightsThunks";

const initialState = {
  isLoading: false,
  isCreating: false,
  flights: [],
  error: null,
}

const flightsSlice = createSlice({
  name: 'flights',
  initialState,
  reducers: {
    resetFlights: (state) => {
      state.flights = initialState.flights;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getFlightList.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(getFlightList.fulfilled, (state, action) => {
        state.isLoading = false;
        state.flights = action.payload?.length ? action.payload : initialState.flights;
      })
      .addCase(getFlightList.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });

    builder
      .addCase(createFlight.pending, (state) => {
        state.isCreating = true;
        state.error = null;
      })
      .addCase(createFlight.fulfilled, (state, action) => {
        state.isCreating = false;
      })
      .addCase(createFlight.rejected, (state, action) => {
        state.isCreating = false;
        state.error = action.payload;
      });
  },
});

export const { resetFlights } = flightsSlice.actions;

export default flightsSlice.reducer;

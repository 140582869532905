import {createSlice} from '@reduxjs/toolkit';
import {AuthUserDetailsTypes} from "../../types/Users/Users.types";

interface InitialStateTypes {
  details: AuthUserDetailsTypes | null,
}

const initialState: InitialStateTypes = {
  details: null,
}

const authUserSlice = createSlice({
  name: 'authUser',
  initialState,
  reducers: {
    setAuthUserDetails: (state, action) => {
      const {sub, family_name, given_name, name, email, email_verified} = action.payload;

      state.details = {
        id: sub,
        firstName: family_name,
        lastName: given_name,
        fullname: name,
        email,
        isEmailVerified: email_verified,
      };
    },
  },
});

export const { setAuthUserDetails } = authUserSlice.actions;

export default authUserSlice.reducer;

import {createAsyncThunk} from "@reduxjs/toolkit";
import {setGlobalError} from "../reducers/errorSlice";
import AIRLINES_API from "../../api/AIRLINES_API";
import {AirlinesSearchResponseType} from "../../types/Airlines/Airlines.types";

export const getAirlinesSearchResults = createAsyncThunk<AirlinesSearchResponseType, { token: string, searchTerm: string }>("airlines/searchResults", async ({token, searchTerm}, { rejectWithValue, dispatch}) => {
  try {
    return await AIRLINES_API.getAirlinesSearchResults(token, searchTerm);
  } catch (error) {
    dispatch(setGlobalError(error.message));
    return rejectWithValue(error.message);
  }
});

import {createAsyncThunk} from "@reduxjs/toolkit";
import {UserDetailsTypes} from "../../types/Users/Users.types";
import {setGlobalError} from "../reducers/errorSlice";
import USERS_API from "../../api/USERS_API";

export const loadUserDetails = createAsyncThunk<UserDetailsTypes, { userId: string, token: string }>("users/loadUserDetails", async ({userId, token}, { rejectWithValue, dispatch}) => {
  try {
    return await USERS_API.getUser(userId, token);
  } catch (error) {
    dispatch(setGlobalError(error.message));
    return rejectWithValue(error.message);
  }
});

import React, {useEffect, useState} from 'react';
import {useAppDispatch, useAppSelector} from "../../hooks/hooks";
import {Alert} from "react-bootstrap";
import {setGlobalError} from "../../redux/reducers/errorSlice";

const GlobalError = () => {
    const {globalError} = useAppSelector((state) => state.errors);
    const dispatch = useAppDispatch();
    const [open, setOpen] = useState(false);

    //Close snackbar
    const handleClose = () => {
        setOpen(false);
    };

    //Show/Hide
    useEffect(() => {
        let timer;

        if(globalError) {
            setOpen(true);

            timer = setTimeout(() => {
                setOpen(false);
                dispatch(setGlobalError(''));
            }, 10000);
        }else{
            setOpen(false);

        }

        return () => {
            clearTimeout(timer);
        };
    }, [globalError, dispatch]);


    return (
        <div className={`global-error ${open ? "show" : "hide"}`}>
            {open ? (
              <Alert variant="danger" onClose={handleClose} dismissible>
                  <p>{globalError}</p>
              </Alert>
            ) : <></>}
        </div>
    );
};

export default GlobalError;

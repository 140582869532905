import React from "react";
import {StyledLogo} from "./Logo.styles";

const Logo = () => {
  return (
    <StyledLogo>
      <h3><span>X</span>rossCheck</h3>
    </StyledLogo>
  );
};

export default Logo;

import {createAsyncThunk} from "@reduxjs/toolkit";
import {setGlobalError} from "../reducers/errorSlice";
import AIRCRAFT_API from "../../api/AIRCRAFT_API";
import {AircraftTypes} from "../../types/Aircraft/Aircraft.types";

export const getAircraftSearchResults = createAsyncThunk<AircraftTypes[], { token: string }>("aircraft/searchResults", async ({token}, { rejectWithValue, dispatch}) => {
  try {
    return await AIRCRAFT_API.getAircraftList(token);
  } catch (error) {
    dispatch(setGlobalError(error.message));
    return rejectWithValue(error.message);
  }
});

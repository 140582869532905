import React from "react";

const Footer = () => {
  return (
    <div className="main-footer">
      <span>&copy; 2024. Crosscombined. All Rights Reserved.</span>
    </div>
  )
}

export default Footer;

import React, {useEffect, useState} from "react";
import {Card, Col, Form, Pagination, Row, Spinner, Table} from "react-bootstrap";
import Header from "../../../layouts/Header";
import Footer from "../../../layouts/Footer";
import {Link} from "react-router-dom";
import Dropdown from "react-bootstrap/Dropdown";
import {ROUTES} from "../../../constants";

import {getDateTimeFromUnix} from "../../../helpers";
import {loadUsersList} from "../../../redux/thunk/usersThunks";
import {RootState} from "../../../redux/store";
import {useAppDispatch, useAppSelector} from "../../../hooks/hooks";
import useAuthUser from "../../../hooks/auth/useAuthUser";
import Avatar from "react-avatar";

const UsersList = () => {
  const {token} = useAuthUser();
  const dispatch = useAppDispatch();
  const {isLoading, usersList} = useAppSelector((state: RootState) => state.users);
  const [isPristine, setIsPristine] = useState(true);

  const UserActionsToggle = React.forwardRef(({children, onClick}: {
    children: React.ReactNode,
    onClick: (e) => void;
  }, ref: React.Ref<HTMLAnchorElement>) => (
    <Link
      to=""
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
      className="dropdown-link"
    >
      {children}
    </Link>
  ));

  useEffect(() => {
    dispatch(loadUsersList({token}));
    setIsPristine(false);
  }, []);

  return (
    <React.Fragment>
      <Header/>
      <div className="main main-app p-3 p-lg-4">
        <div className="d-md-flex align-items-center justify-content-between mb-4">
          <div>
            <ol className="breadcrumb fs-sm mb-1">
              <li className="breadcrumb-item"><Link to={`/${ROUTES.DASHBOARD}`}>Dashboard</Link></li>
              <li className="breadcrumb-item active" aria-current="page">Users list</li>
            </ol>
            <h4 className="main-title mb-0">Users list</h4>
          </div>
          <div className="d-flex align-items-center gap-2 mt-3 mt-md-0">
            <div className="form-search me-auto">
              <input type="text" className="form-control" placeholder="Search users"/>
              <i className="ri-search-line"></i>
            </div>
          </div>
        </div>
        <Row className="g-3">
          <Col xs="12">
            <Card className="card-one">
              <Card.Body>
                {isLoading ? (
                  <Spinner animation="border" variant="primary"/>
                ) : <></>}

                {!isPristine && !isLoading && usersList?.length ? (
                  <Table className="table-users mb-0" responsive>
                    <thead>
                    <tr>
                      <th>
                        <Form.Check type="checkbox"/>
                      </th>
                      <th>Name</th>
                      <th>Email</th>
                      <th>Phone</th>
                      <th>Registration date</th>
                      <th>Status</th>
                      <th>&nbsp;</th>
                    </tr>
                    </thead>
                    <tbody>
                    {usersList.map((item, index) => (
                      <tr key={index}>
                        <td>
                          <Form.Check type="checkbox"/>
                        </td>
                        <td>
                          <Link to={item.id}>
                            <div className="d-flex align-items-center gap-3">
                              <Avatar name={`${item.firstName} ${item.lastName}`} round={true} size="42px" />
                              <div>
                                <h6 className="mb-0">{`${item.firstName} ${item.lastName}`}</h6>
                              </div>
                            </div>
                          </Link>
                        </td>
                        <td><span
                          className={`ff-numerals ${!item.emailVerified ? "text-danger" : "text-dark"}`}>{item.email}</span>
                        </td>
                        <td><span
                          className={`ff-numerals ${!item.phoneVerified ? "text-danger" : "text-dark"}`}>{item.phone}</span>
                        </td>
                        <td><span className="text-gray-600">{getDateTimeFromUnix(item.createdTimestamp)}</span>
                        </td>
                        <td>
                                                <span className={`badge bg-${item.enabled ? "success" : "secondary"}`}>
                                                    {item.enabled ? "active" : "inactive"}
                                                </span>
                        </td>
                        <td>
                          <div className="d-flex justify-content-end">
                            <Dropdown align="end" className="dropdown-profile">
                              <Dropdown.Toggle as={UserActionsToggle} className="link-more">
                                <i className="ri-more-2-fill"></i>
                              </Dropdown.Toggle>
                              <Dropdown.Menu className="p-2">
                                <Dropdown.Item href={`users/${item.id}`}
                                               className="d-flex gap-2 align-items-center"><i
                                  className="ri-profile-line"></i> Profile details</Dropdown.Item>
                              </Dropdown.Menu>
                            </Dropdown>
                          </div>
                        </td>
                      </tr>
                    ))}
                    </tbody>
                  </Table>
                ) : <></>}

                {!isPristine && !isLoading && !usersList?.length ? (
                  <p className="mb-0">No users</p>
                ) : <></>}

                {usersList?.length > 20 ? (
                  <Pagination className="pagination-space pagination-circled mb-0 mt-4">
                    <Pagination.Item disabled><i className="ri-arrow-left-s-line"></i></Pagination.Item>
                    <Pagination.Item active>1</Pagination.Item>
                    <Pagination.Item>2</Pagination.Item>
                    <Pagination.Item>3</Pagination.Item>
                    <Pagination.Item><i className="ri-arrow-right-s-line"></i></Pagination.Item>
                  </Pagination>
                ) : (
                  <></>
                )}

              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Footer/>
      </div>
    </React.Fragment>
  )
}

export default UsersList;

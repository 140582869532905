import React from "react";
import {Alert, Button, Card} from "react-bootstrap";
import Logo from "../../components/Logo/Logo";
import {useAuth} from "react-oidc-context";


const Signin: React.FC = () => {
  const auth = useAuth();
  const handleSignIn = () => {
    void auth.signinRedirect();
  }

  return (
    <div className="page-sign">
      <div className="logoWrapper">
        <Logo/>
      </div>
      <Card className="card-sign">
        <Card.Header>
          <Card.Title>Sign In</Card.Title>
          <p className="text-gray-600">Welcome back! Please sign in to continue.</p>
        </Card.Header>

        <Card.Body className="pt-0">
          <Button variant="primary" className="btn-sign" disabled={auth.isLoading} onClick={handleSignIn}>
            {auth.isLoading ? "Authorization..." : "Sign in"}
          </Button>
          {auth.error?.message && (
            <Alert variant="danger" className="mt-4">
              {auth.error?.message}
            </Alert>
          )}
        </Card.Body>
      </Card>
    </div>
  )
}

export default Signin

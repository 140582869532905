import React from "react";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import NotFound from "./pages/NotFound/NotFound";

import publicRoutes from "./routes/PublicRoutes";
import protectedRoutes from "./routes/ProtectedRoutes";

import "./assets/css/remixicon.css";
import "./scss/style.scss";
import Signin from "./pages/SignIn/Signin";
import {useAuth} from "react-oidc-context";
import Main from "./layouts/Main";
import GlobalError from "./components/GlobalError/GlobalError";
import AppRouter from "./routes/AppRouter";


// set skin on load
window.addEventListener("load", function () {
  let skinMode = localStorage.getItem("skin-mode");
  let HTMLTag = document.querySelector("html");

  if (skinMode) {
    HTMLTag.setAttribute("data-skin", skinMode);
  }
});

export default function App() {

  return (
    <React.Fragment>
      <AppRouter/>
      <GlobalError/>
    </React.Fragment>

  );
}

import {createAsyncThunk} from "@reduxjs/toolkit";
import {setGlobalError} from "../reducers/errorSlice";
import {AssignFlightRequestTypes, CrewMemberItemTypes} from "../../types/Crews/CrewsTypes";
import CREWS_API from "../../api/CREWS_API";
import {UserTypes} from "../../types/Users/Users.types";


export const loadCrewMembersList = createAsyncThunk<CrewMemberItemTypes[], { token: string }>("crews/loadCrewMembers", async ({token}, { rejectWithValue, dispatch}) => {
  try {
    return await CREWS_API.getCrewMembers(token);
  } catch (error) {
    dispatch(setGlobalError(error.message));
    return rejectWithValue(error.message);
  }
});

export const assignFlightToCrewMember = createAsyncThunk<any, {
  token: string,
  crewUserId: string,
  flightId: number,
}>("crews/assignFlight", async ({token, crewUserId, flightId}, {rejectWithValue, dispatch}) => {
  try {
    const assignFlightData: AssignFlightRequestTypes = {
      notes: "",
      status: "Active",
      flightIds: [flightId]
    }
    const assignFlightRes = await CREWS_API.assignFlightToCrewMember(token, crewUserId, assignFlightData);

    if (assignFlightRes) {
      return assignFlightRes;
    }
  } catch (error) {
    dispatch(setGlobalError(error.message));
    return rejectWithValue(error.message);
  }
});


import {createAsyncThunk} from "@reduxjs/toolkit";
import {setGlobalError} from "../reducers/errorSlice";
import {CreateFlightDataTypes, FlightTypes, NewFlightFormFieldsTypes} from "../../types/Flight/Flight.types";
import FLIGHT_API from "../../api/FLIGHT_API";
import {getISODateTime} from "../../helpers";
import {assignFlightToCrewMember} from "./crewsThunks";

export const getFlightList = createAsyncThunk<FlightTypes[], {
  token: string
}>("flights/updateFlights", async ({token}, {rejectWithValue, dispatch}) => {
  try {
    return await FLIGHT_API.getFlightList(token);
  } catch (error) {
    dispatch(setGlobalError(error.message));
    return rejectWithValue(error.message);
  }
});

export const createFlight = createAsyncThunk<any, {
  token: string,
  formFields: NewFlightFormFieldsTypes,
  callback: () => void
}>("flights/createFlight", async ({token, formFields, callback}, {rejectWithValue, dispatch}) => {
  try {
    const flightData: CreateFlightDataTypes = {
      notes: "",
      status: "Active",
      code: formFields.flightCode,
      number: formFields.flightNumber,
      alternativeCode: formFields.flightCode,
      alternativeNumber: formFields.flightNumber,
      airlineId: +formFields.airline,
      aircraftId: +formFields.aircraft,
      departureAirportId: +formFields.from,
      arrivalAirportId: +formFields.to,
      departureTime: getISODateTime(formFields.departureTime),
      arrivalTime: getISODateTime(formFields.arrivalTime),
    }
    const createFlightRes = await FLIGHT_API.createFlight(token, flightData);

    if (createFlightRes) {
      const flightId = createFlightRes.id;

      //Assign flight to crew members
      if(formFields.crewMembers?.length) {
        formFields.crewMembers.forEach(crewUserId => {
          dispatch(assignFlightToCrewMember({token, crewUserId, flightId}))
        });
      }

      dispatch(getFlightList({token}));
      callback();
      return createFlightRes;
    }
  } catch (error) {
    dispatch(setGlobalError(error.message));
    return rejectWithValue(error.message);
  }
});

export const deleteFlight = createAsyncThunk<any, {
  token: string,
  flightId: number
}>("flights/deleteFlight", async ({token, flightId}, {rejectWithValue, dispatch}) => {
  try {
    const res = await FLIGHT_API.deleteFlight(token, flightId);
    dispatch(getFlightList({token}));
    return res;
  } catch (error) {
    dispatch(setGlobalError(error.message));
    return rejectWithValue(error.message);
  }
});

import {handleRequest} from "./API";
import {CreateFlightDataTypes, CreateFlightResponseTypes, FlightTypes} from "../types/Flight/Flight.types";

const FLIGHT_API = {
  getFlightList: async (token: string) => {
    return await handleRequest<FlightTypes[]>(
      "get",
      `flights`,
      token
    );
  },
  createFlight: async (token: string, flightData: CreateFlightDataTypes) => {
    return await handleRequest<CreateFlightResponseTypes>(
      "post",
      `flights`,
      token,
      flightData
    );
  },
  deleteFlight: async (token: string, flightId: number) => {
    return await handleRequest(
      "delete",
      `flights/${flightId}`,
      token
    );
  }
}

export default FLIGHT_API;

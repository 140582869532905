import React, {useRef, useState} from "react";
import {Link, NavLink} from "react-router-dom";
import PerfectScrollbar from "react-perfect-scrollbar";
import {dashboardMenu} from "../data/Menu";
import Logo from "../components/Logo/Logo";
import {MenuItem} from "../types/Sidebar/SidebarTypes";
import useAuthUser from "../hooks/auth/useAuthUser";
import Avatar from "react-avatar";

const Sidebar: React.FC = () => {
  const scrollBarRef = useRef<PerfectScrollbar>(null);
  const {authUserDetails, handleLogout} = useAuthUser();

  const toggleFooterMenu = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    e.preventDefault();
    const parent = (e.target as HTMLElement).closest('.sidebar');
    if (parent) {
      parent.classList.toggle('footer-menu-show');
    }
  };

  return (
    <div className="sidebar">
      <div className="sidebar-header">
        <Logo />
      </div>
      <PerfectScrollbar className="sidebar-body" ref={scrollBarRef}>
        <SidebarMenu menuItems={dashboardMenu} onUpdateSize={() => scrollBarRef.current?.updateScroll()} />
      </PerfectScrollbar>
      <div className="sidebar-footer">
        <div className="sidebar-footer-top">
          <div className="sidebar-footer-thumb">
            <Avatar name={authUserDetails?.fullname || ""} round={true} size="36px"/>
          </div>
          <div className="sidebar-footer-body">
            <h6>
              <Link to="" onClick={toggleFooterMenu}>{authUserDetails?.fullname || ""}</Link>
            </h6>
            <p>Manager</p>
          </div>
          <Link onClick={toggleFooterMenu} to="" className="dropdown-link">
            <i className="ri-arrow-down-s-line"></i>
          </Link>
        </div>
        <div className="sidebar-footer-menu">
          <nav className="nav">
            <Link to="">
              <i className="ri-user-settings-line"></i> Settings
            </Link>
          </nav>
          <hr />
          <nav className="nav">
            <Link to="" onClick={handleLogout}>
              <i className="ri-logout-box-r-line"></i> Log Out
            </Link>
          </nav>
        </div>
      </div>
    </div>
  );
};

interface SidebarMenuProps {
  menuItems?: MenuItem[];
  onUpdateSize?: () => void;
}

const SidebarMenu: React.FC<SidebarMenuProps> = ({ menuItems, onUpdateSize }) => {
  const [isMenuGroupOpen, setIsMenuGroupOpen] = useState(true);

  const toggleMenu = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.preventDefault();
    setIsMenuGroupOpen(!isMenuGroupOpen);
    onUpdateSize();
  };

  const toggleSubMenu = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.preventDefault();
    const parent = (e.target as HTMLElement).closest('.nav-item');
    const nodes = parent?.parentNode?.childNodes;

    if (nodes) {
      nodes.forEach((node: ChildNode) => {
        if (node !== parent && node.nodeType === Node.ELEMENT_NODE) {
          (node as HTMLElement).classList.remove('show');
        }
      });
    }

    parent?.classList.toggle('show');
    onUpdateSize();
  };

  const populateMenu = (menu: MenuItem[]) => {
    return menu.map((item: MenuItem, key: number) => {
      if (item.submenu) {
        const submenu = item.submenu.map((subItem: MenuItem, subKey: number) => (
          <NavLink to={subItem.link} className="nav-sub-link" key={subKey}>
            {subItem.label}
          </NavLink>
        ));

        return (
          <li key={key} className="nav-item">
            <div onClick={toggleSubMenu} className="nav-link has-sub">
              <i className={item.icon}></i>
              <span>{item.label}</span>
            </div>
            <nav className="nav nav-sub">{submenu}</nav>
          </li>
        );
      }

      return (
        <li key={key} className="nav-item">
          <NavLink to={item.link} className="nav-link">
            <i className={item.icon}></i>
            <span>{item.label}</span>
          </NavLink>
        </li>
      );
    });
  };

  return (
    <React.Fragment>
      <div className={`nav-group ${isMenuGroupOpen ? 'show' : ''}`}>
        <div className="nav-label" onClick={toggleMenu}>
          Dashboard
        </div>
        <ul className="nav nav-sidebar">{populateMenu(menuItems)}</ul>
      </div>
    </React.Fragment>
  );
};

window.addEventListener("click", function (e: MouseEvent) {
  const target = e.target as HTMLElement;

  // Close sidebar footer menu when clicked outside of it
  let sidebar = document.querySelector(".sidebar");
  if (!target.closest(".sidebar-footer") && sidebar) {
    sidebar.classList.remove("footer-menu-show");
  }

  // Hide sidebar offset when clicked outside of sidebar
  if (!target.closest(".sidebar") && !target.closest(".menu-link")) {
    document.querySelector("body")?.classList.remove("sidebar-show");
  }
});

window.addEventListener("load", function () {
  let skinMode = localStorage.getItem("sidebar-skin");
  let HTMLTag = document.querySelector("html");

  if (skinMode) {
    HTMLTag.setAttribute("data-sidebar", skinMode);
  }
});

export default Sidebar;

import {createSlice} from '@reduxjs/toolkit';
import {getAirlinesSearchResults} from "../thunk/airlinesThunks";

const initialState = {
  isLoading: false,
  airlinesSearchResults: [],
  error: null,
}

const airlinesSlice = createSlice({
  name: 'airlines',
  initialState,
  reducers: {
    resetAirlinesSearch: (state) => {
      state.airlinesSearchResults = initialState.airlinesSearchResults;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAirlinesSearchResults.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(getAirlinesSearchResults.fulfilled, (state, action) => {
        state.isLoading = false;
        state.airlinesSearchResults = action.payload?.items?.length ? action.payload.items : [];
      })
      .addCase(getAirlinesSearchResults.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });
  },
});

export const { resetAirlinesSearch } = airlinesSlice.actions;

export default airlinesSlice.reducer;

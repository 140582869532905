import { createSlice } from '@reduxjs/toolkit';
import {UserDetailsTypes} from "../../types/Users/Users.types";
import {loadUserDetails} from "../thunk/userDetailsThunks";

interface InitialStateTypes {
  isLoading: boolean,
  details: UserDetailsTypes | null,
  error: string | null,
}

const initialState: InitialStateTypes = {
  isLoading: false,
  details: null,
  error: null,
}

const userDetailsSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(loadUserDetails.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(loadUserDetails.fulfilled, (state, action) => {
        state.isLoading = false;
        state.details = action.payload ? action.payload : null;
      })
      .addCase(loadUserDetails.rejected, (state, action) => {
        state.isLoading = false;
        state.error = `${action.payload}`;
      });
  },
});

export default userDetailsSlice.reducer;

import React, {useState} from 'react';
import Header from "../../../layouts/Header";
import {Link} from "react-router-dom";
import {ROUTES} from "../../../constants";
import {Button, Card, Col, Form, Modal, Row, Spinner, Table} from "react-bootstrap";
import UserAvatar from "../../../assets/img/user.jpg";
import {getDateTimeFromUnix} from "../../../helpers";
import Footer from "../../../layouts/Footer";

const CustomerDetails = () => {
  const [showEditCustomerModal, setShowEditCustomerModal] = useState(false);

  const handleCloseEditCustomerModal = () => setShowEditCustomerModal(false);
  const handleShowEditCustomerModal = () => setShowEditCustomerModal(true);

  return (
    <React.Fragment>
      <Header/>
      <div className="main main-app p-3 p-lg-4">
        <div className="d-md-flex align-items-center justify-content-between mb-4">
          <div>
            <ol className="breadcrumb fs-sm mb-1">
              <li className="breadcrumb-item"><Link to={`/${ROUTES.DASHBOARD}`}>Dashboard</Link></li>
              <li className="breadcrumb-item"><Link to={`/${ROUTES.CUSTOMERS}`}>Customers</Link></li>
              <li className="breadcrumb-item active"
                  aria-current="page">{`Customer title`}
              </li>
            </ol>
          </div>
        </div>

          <Row className="g-5">
            <Col xl>
              <Row>
                <Col>
                  <div className="media-profile mb-5">
                    <div className="media-img mb-3 mb-sm-0">
                      <img src={UserAvatar} className="img-fluid" alt="..."/>
                    </div>
                    <div className="media-body">
                      <h5 className="media-name">Customer title</h5>
                      <h6>Abbreviation</h6>
                      <p className="d-flex gap-2 mb-4 text-success"><i className="ri-radio-button-line"></i> Active customer</p>
                    </div>
                  </div>
                </Col>
                <Col className="d-flex justify-content-end">
                  <div className="btn-container align-self-start">
                    <Button
                      variant="outline-primary"
                      className="d-flex align-items-center gap-1"
                      onClick={handleShowEditCustomerModal}
                    >
                      <i className="ri-edit-2-line"></i> Edit customer
                    </Button>
                  </div>
                </Col>
              </Row>

            </Col>
          </Row>

        <Row className="g-3">
          <Col xs="12">
            <Card className="card-one">
              <Card.Body>
                <h6>Notes</h6>
                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Animi esse, ex expedita illum impedit iure libero porro quas reprehenderit rerum tenetur, vitae. Ex, placeat saepe! A consequuntur esse expedita harum impedit ipsam iste iusto magni maxime minus nam neque non pariatur quae quam quas quasi quidem repellendus rerum, sapiente sequi sunt, tenetur. Alias deserunt dicta dolore harum molestias nesciunt quidem sint sunt! Incidunt, laboriosam, rerum? A, delectus error? Aliquid dolorem doloremque magnam quasi velit. Inventore nam placeat quidem sapiente suscipit. Accusamus autem consequatur, corporis culpa cum dignissimos eveniet ex molestias necessitatibus neque repellendus suscipit totam vero. Earum eius quisquam voluptas.</p>
              </Card.Body>
            </Card>
          </Col>
        </Row>

        <Footer />
      </div>

      <Modal
        show={showEditCustomerModal}
        onHide={handleCloseEditCustomerModal}
        centered={true}
        size={"lg"}
      >
        <Modal.Header closeButton>
          <Modal.Title>Edit customer details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3">
              <Form.Control type="text" placeholder="Title" value="Customer title" />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Control type="text" placeholder="Abbreviation" value="Abbreviation" />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Control as="textarea" rows={3} placeholder="Notes" value="Notes" />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Check type="switch" id="active-inactive-switch" label="Active" />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="info" onClick={handleCloseEditCustomerModal}>
            Close
          </Button>
          <Button variant="primary" onClick={handleCloseEditCustomerModal}>
            Save customer details
          </Button>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
};

export default CustomerDetails;

import React, {useEffect} from "react";
import {Outlet, useLocation} from "react-router-dom";
import Sidebar from "./Sidebar";
import {useAppDispatch} from "../hooks/hooks";
import useAuthUser from "../hooks/auth/useAuthUser";
import {decodeToken} from "react-jwt";
import {setAuthUserDetails} from "../redux/reducers/authUserSlice";
import {DecodedIdTokenTypes} from "../types/Users/Users.types";
import {setGlobalError} from "../redux/reducers/errorSlice";

const Main = () => {
  const offsets = ["/apps/file-manager", "/apps/email", "/apps/calendar"];
  const {pathname} = useLocation();
  const bc = document.body.classList;

  const dispatch = useAppDispatch();
  const {idToken, token} = useAuthUser();

  // set sidebar to offset
  (offsets.includes(pathname)) ? bc.add("sidebar-offset") : bc.remove("sidebar-offset");

  // auto close sidebar when switching pages in mobile
  bc.remove("sidebar-show");

  // scroll to top when switching pages
  window.scrollTo(0, 0);

  //Decode authenticated user data
  useEffect(() => {
    if(idToken) {
      const decodedIdToken: DecodedIdTokenTypes = decodeToken(idToken);
      if(decodedIdToken?.sub) {
        dispatch(setAuthUserDetails(decodedIdToken));
      }else{
        dispatch(setGlobalError("Error decoding the data of the authorized user"))
      }
    }
  }, [idToken]);

  return (
    <React.Fragment>
      <Sidebar/>
      <Outlet/>
    </React.Fragment>
  )
}

export default Main;

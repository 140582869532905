import {useAuth} from "react-oidc-context";
import {useAppSelector} from "../hooks";

const useAuthUser = () => {
  const auth = useAuth();
  const token = auth.user.access_token;
  const idToken = auth.user.id_token;

  const {details: authUserDetails} = useAppSelector(state => state.authUser)

  const handleLogout = async () => {
    await auth.signoutRedirect({
      id_token_hint: idToken,
      post_logout_redirect_uri: process.env.REACT_APP_KEYCLOAK_REDIRECT_URI
    })
    await auth.removeUser();
  }

  return {token, idToken, authUserDetails, handleLogout}
};

export default useAuthUser;

import {UserDetailsTypes, UserTypes} from "../types/Users/Users.types";
import {handleRequest} from "./API";

const USERS_API = {
  getUsers: async (token: string) => {
    return await handleRequest<UserTypes[]>(
      "get",
      `users`,
      token,
    );
  },
  getUser: async (userId: string, token: string) => {
    return await handleRequest<UserDetailsTypes>(
      "get",
      `users/${userId}`,
      token,
    );
  },
}

export default USERS_API;

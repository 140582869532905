import {handleRequest} from "./API";
import {AircraftTypes} from "../types/Aircraft/Aircraft.types";

const AIRCRAFT_API = {
  getAircraftList: async (token: string) => {
    return await handleRequest<AircraftTypes[]>(
      "get",
      `aircraft`,
      token
    );
  }
}

export default AIRCRAFT_API;

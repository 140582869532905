import React, {useCallback, useEffect} from "react";
import {Link, useParams} from "react-router-dom";
import {Button, Card, Col, Row, Spinner, Table} from "react-bootstrap";
import Header from "../../../layouts/Header";
import Footer from "../../../layouts/Footer";
import {ROUTES} from "../../../constants";
import {getDateTimeFromUnix} from "../../../helpers";
import {useAppDispatch, useAppSelector} from "../../../hooks/hooks";
import {RootState} from "../../../redux/store";
import {loadUserDetails} from "../../../redux/thunk/userDetailsThunks";
import useAuthUser from "../../../hooks/auth/useAuthUser";
import Avatar from "react-avatar";

const UserDetails = () => {
  const {userId} = useParams();
  const dispatch = useAppDispatch();
  const {isLoading, details} = useAppSelector((state: RootState) => state.userDetails)
  const {token} = useAuthUser();

  const handleEditUser = useCallback(() => {
    window.open(`${process.env.REACT_APP_USER_EDIT_URL}/${userId}/settings`, '_blank');
  }, [])

  useEffect(() => {
    dispatch(loadUserDetails({userId, token}));
  }, []);
  return (
    <React.Fragment>
      <Header/>
      <div className="main main-app p-3 p-lg-4">
        <div className="d-md-flex align-items-center justify-content-between mb-4">
          <div>
            <ol className="breadcrumb fs-sm mb-1">
              <li className="breadcrumb-item"><Link to={`/${ROUTES.DASHBOARD}`}>Dashboard</Link></li>
              <li className="breadcrumb-item"><Link to={`/${ROUTES.USERS}`}>Users list</Link></li>
              {details?.firstName && details?.lastName ? (
                <li className="breadcrumb-item active"
                    aria-current="page">{`${details.firstName} ${details.lastName}`}
                </li>
              ) : (<></>)}
            </ol>
          </div>
        </div>

        {isLoading ? (
          <Spinner animation="border" variant="primary" />
        ) : <></>}

        {!isLoading && !details?.id ? (
          <p>Loading data error</p>
        ) : <></>}

        {!isLoading && details?.id ? (
          <Row className="g-5">
            <Col xl>
              <Row>
                <Col>
              <div className="media-profile mb-5">
                <div className="media-img mb-3 mb-sm-0">
                  <Avatar name={`${details.firstName} ${details.lastName}`} round={true} size="100px" />
                </div>
                <div className="media-body">
                  <h5 className="media-name">{`${details.firstName} ${details.lastName}`}</h5>
                  {details.enabled ? (
                    <p className="d-flex gap-2 mb-4 text-success"><i className="ri-radio-button-line"></i> Active user</p>
                  ) : (
                    <p className="d-flex gap-2 mb-4 text-danger"><i className="ri-radio-button-line"></i> Disabled user
                    </p>
                  )}

                </div>
              </div>
                </Col>
            <Col className="d-flex justify-content-end">
              <div className="btn-container align-self-start">
                <Button
                  variant="outline-primary"
                  className="d-flex align-items-center gap-1"
                  onClick={handleEditUser}
                >
                  <i className="ri-edit-2-line"></i> Edit user
                </Button>
              </div>
            </Col>
              </Row>

              <Card>
                <Table className="table-user-info table table-striped" responsive>
                  <tbody>
                  <tr className="title">
                    <th><b>Personal Information</b></th>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                  </tr>
                  <tr>
                    <td>
                      <p>First name</p>
                    </td>
                    <td className="selected"><p>{details.firstName}</p></td>
                    <td></td>
                  </tr>
                  <tr>
                    <td>
                      <p>Last name</p>
                    </td>
                    <td className="selected"><p>{details.lastName}</p></td>
                    <td></td>
                  </tr>
                  <tr>
                    <td>
                      <p>Email</p>
                    </td>
                    <td className="selected">
                      <p>{details.email}</p>
                      {details.emailVerified ? (<small className="text-success">Verified</small>) : (
                        <small className="text-danger">Not verified</small>)}
                    </td>
                    <td></td>
                  </tr>
                  <tr>
                    <td>
                      <p>Phone</p>
                    </td>
                    <td className="selected">
                      <p>{details.phone}</p>
                      {details.phoneVerified ? (<small className="text-success">Verified</small>) : (
                        <small className="text-danger">Not verified</small>)}
                    </td>
                    <td></td>
                  </tr>
                  <tr>
                    <td>
                      <p>Registration date</p>
                    </td>
                    <td className="selected"><p>{getDateTimeFromUnix(details.createdTimestamp)}</p></td>
                    <td></td>
                  </tr>
                  <tr>
                    <td>
                      <p>User status</p>
                    </td>
                    <td className="selected">
                      {details.enabled ? (<p className="text-success">Enabled</p>) : (
                        <p className="text-danger">Disabled</p>)}

                    </td>
                    <td></td>
                  </tr>

                  </tbody>
                </Table>
              </Card>
            </Col>
          </Row>

        ) : <></>}


        <Footer/>
      </div>
    </React.Fragment>
  );
}

export default UserDetails;

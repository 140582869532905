import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import authConfig from "./services/auth/authConfig";
import {AuthProvider} from "react-oidc-context";
import {Provider} from "react-redux";
import store from "./redux/store";


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <AuthProvider {...authConfig}>
    <Provider store={store}>
    <App/>
    <div className="main-backdrop"></div>
    </Provider>
  </AuthProvider>,
);

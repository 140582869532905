import {createAsyncThunk} from "@reduxjs/toolkit";
import {setGlobalError} from "../reducers/errorSlice";
import {
  BookingListItemTypes,
  CreateBookingDataTypes,
  CreateBookingDetailsDataTypes,
  NewBookingFormFieldsTypes
} from "../../types/Booking/Booking.types";
import BOOKING_API from "../../api/BOOKING_API";
import {FlightTypes} from "../../types/Flight/Flight.types";

export const getBookingList = createAsyncThunk<BookingListItemTypes[], {
  token: string
}>("bookings/updateBookings", async ({token}, {rejectWithValue, dispatch}) => {
  try {
    return await BOOKING_API.getBookings(token);
  } catch (error) {
    dispatch(setGlobalError(error.message));
    return rejectWithValue(error.message);
  }
});

export const createBooking = createAsyncThunk<any, {
  token: string,
  formFields: NewBookingFormFieldsTypes,
  flightData: FlightTypes,
  callback: () => void
}>("bookings/createBooking", async ({token, formFields, flightData, callback}, {rejectWithValue, dispatch}) => {
  try {
    const bookingData: CreateBookingDataTypes = {
      reference: formFields.bookingId,
      code: formFields.lastName,
    }
    const createBookingRes = await BOOKING_API.createBooking(token, bookingData);

    if (createBookingRes) {
      const detailsData: CreateBookingDetailsDataTypes = {
        firstName: formFields.firstName,
        lastName: formFields.lastName,
        airlineName: flightData.airline.name,
        airlineCode: flightData.airline.icao,
        flightCode: flightData.code,
        flightNumber: flightData.number,
        seatClass: formFields.seatClass,
        seatNumber: formFields.seatNumber,
        departureAirportName: flightData.departureAirport.name,
        departureAirportCode: flightData.departureAirport.icao,
        arrivalAirportName: flightData.arrivalAirport.name,
        arrivalAirportCode: flightData.arrivalAirport.icao,
        departureTime: flightData.departureTime,
        arrivalTime: flightData.arrivalTime,
      }
      const createBookingDetailsRes = await BOOKING_API.createBookingDetails(token, createBookingRes.id, detailsData);
      dispatch(getBookingList({token}));
      callback();
      return createBookingDetailsRes;
    }
  } catch (error) {
    dispatch(setGlobalError(error.message));
    return rejectWithValue(error.message);
  }
});

export const deleteBooking = createAsyncThunk<any, {
  token: string,
  bookingId: number
}>("bookings/deleteBooking", async ({token, bookingId}, {rejectWithValue, dispatch}) => {
  try {
    const res = await BOOKING_API.deleteBooking(token, bookingId);
    dispatch(getBookingList({token}));
    return res;
  } catch (error) {
    dispatch(setGlobalError(error.message));
    return rejectWithValue(error.message);
  }
});

import { createSlice } from '@reduxjs/toolkit';
import {getAircraftSearchResults} from "../thunk/aircraftThunks";

const initialState = {
  isLoading: false,
  aircraftSearchResults: [],
  error: null,
}

const aircraftSlice = createSlice({
  name: 'aircraft',
  initialState,
  reducers: {
    resetAircraftSearch: (state) => {
      state.aircraftSearchResults = initialState.aircraftSearchResults;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAircraftSearchResults.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(getAircraftSearchResults.fulfilled, (state, action) => {
        state.isLoading = false;
        state.aircraftSearchResults = action.payload.length ? action.payload : [];
      })
      .addCase(getAircraftSearchResults.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });
  },
});

export const { resetAircraftSearch } = aircraftSlice.actions;

export default aircraftSlice.reducer;

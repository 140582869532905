import {handleRequest} from "./API";
import {AssignFlightRequestTypes, CrewMemberItemTypes} from "../types/Crews/CrewsTypes";

const CREWS_API = {
  getCrewMembers: async (token: string) => {
    return await handleRequest<CrewMemberItemTypes[]>(
      "get",
      "crews/users",
      token
    );
  },
  assignFlightToCrewMember: async (token: string, crewUserId: string, assignFlightData: AssignFlightRequestTypes) => {
    return await handleRequest(
      "patch",
      `crews/${crewUserId}/flights`,
      token,
      assignFlightData
    );
  },
}

export default CREWS_API;

import React, {useCallback, useEffect, useState} from 'react';
import Header from "../../../layouts/Header";
import {Link} from "react-router-dom";
import {ROUTES} from "../../../constants";
import {Button, Card, Col, Form, Modal, Pagination, Row, Spinner, Table} from "react-bootstrap";
import Footer from "../../../layouts/Footer";
import {getDateTimeFromIso} from "../../../helpers";
import Dropdown from "react-bootstrap/Dropdown";
import useAuthUser from "../../../hooks/auth/useAuthUser";
import {useAppDispatch, useAppSelector} from "../../../hooks/hooks";
import {RootState} from "../../../redux/store";
import {Typeahead} from "react-bootstrap-typeahead";
import {ErrorMessage, Field, Form as FormikForm, Formik} from 'formik';
import * as Yup from 'yup';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import {getAircraftSearchResults} from "../../../redux/thunk/aircraftThunks";
import {createFlight, deleteFlight, getFlightList} from "../../../redux/thunk/flightsThunks";
import {getAirlinesSearchResults} from "../../../redux/thunk/airlinesThunks";
import {getAirportsSearchResults} from "../../../redux/thunk/airportsThunks";
import {AirportItemTypes} from "../../../types/Airports/Airports.types";
import {UserTypes} from "../../../types/Users/Users.types";
import {AircraftTypes} from "../../../types/Aircraft/Aircraft.types";
import {loadCrewMembersList} from "../../../redux/thunk/crewsThunks";
import {AirlineItemType} from "../../../types/Airlines/Airlines.types";


const validationSchema = Yup.object({
  aircraft: Yup.string().required('Required'),
  airline: Yup.string().required('Required'),
  from: Yup.string().required('Required'),
  to: Yup.string().required('Required'),
  flightCode: Yup.string().required('Required'),
  flightNumber: Yup.string().required('Required'),
  departureTime: Yup.string().required('Required'),
  arrivalTime: Yup.string().required('Required'),
  crewMembers: Yup.array().of(Yup.string()).required('Required'),
});

const Flights = () => {
  const {token} = useAuthUser();
  const dispatch = useAppDispatch();

  const [isPristine, setIsPristine] = useState(true);

  const {isLoading: isAircraftLoading, aircraftSearchResults} = useAppSelector((state: RootState) => state.aircraft);
  const {isLoading: isAirlinesLoading, airlinesSearchResults} = useAppSelector((state: RootState) => state.airlines);
  const {isLoading: isAirportsLoading, airportsSearchResults} = useAppSelector((state: RootState) => state.airports);
  const {isLoading: isLoadingMembers, crewsUsersList} = useAppSelector((state: RootState) => state.crews);
  const {
    isLoading: isFlightsLoading,
    isCreating: isCreatingFlight,
    flights
  } = useAppSelector((state: RootState) => state.flights);

  const [showAddFlightModal, setShowAddFlightModal] = useState(false);

  const handleSearchAircraft = useCallback(() => {
    dispatch(getAircraftSearchResults({token}));
  }, [token])

  const handleSearchAirline = useCallback((searchTerm: string) => {
    dispatch(getAirlinesSearchResults({token, searchTerm}));
  },[token])

  const handleUpdateCrewMemberList = useCallback(() => {
    dispatch(loadCrewMembersList({token}));
  },[token])

  const handleSearchAirports = useCallback((searchTerm: string) => {
    searchTerm && dispatch(getAirportsSearchResults({token, searchTerm}));
  },[token])

  const handleCloseAddFlightModal = () => setShowAddFlightModal(false);
  const handleShowAddFlightModal = () => setShowAddFlightModal(true);

  const FlightActionsToggle = React.forwardRef(({children, onClick}: {
    children: React.ReactNode,
    onClick: (e) => void;
  }, ref: React.Ref<HTMLAnchorElement>) => (
    <Link
      to=""
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
      className="dropdown-link"
    >
      {children}
    </Link>
  ));

  const handleUpdateFlights = () => {
    dispatch(getFlightList({token}));
  }

  useEffect(() => {
    handleUpdateFlights();
    handleUpdateCrewMemberList();
    setIsPristine(false);
  }, []);


  return (
    <React.Fragment>
      <Header/>
      <div className="main main-app p-3 p-lg-4">
        <div className="d-md-flex align-items-center justify-content-between mb-4">
          <div className="w-100">
            <ol className="breadcrumb fs-sm mb-1">
              <li className="breadcrumb-item"><Link to={`/${ROUTES.DASHBOARD}`}>Dashboard</Link></li>
              <li className="breadcrumb-item active" aria-current="page">Flights</li>
            </ol>
            <Row className="g-3">
              <Col md={6}>
                <h4 className="main-title mb-0">Flight list</h4>
              </Col>
              <Col md={6} className="d-flex justify-content-end">
                <div className="btn-container align-self-start">
                  <Button
                    variant="outline-primary"
                    className="d-flex align-items-center gap-1"
                    onClick={handleShowAddFlightModal}
                  >
                    <i className="ri-add-line"></i> Create flight
                  </Button>
                </div>
              </Col>
            </Row>

          </div>
        </div>
        <Row className="g-3">
          <Col xs="12">
            <Card className="card-one">
              <Card.Body>
                {isFlightsLoading && !flights.length ? (
                  <Spinner animation="border" variant="primary"/>
                ) : <></>}

                {!isPristine && flights?.length ? (
                  <Table className="table-users mb-0" responsive>
                    <thead>
                    <tr>
                      <th>
                        <Form.Check type="checkbox"/>
                      </th>
                      <th>Code</th>
                      <th>Number</th>
                      <th>From</th>
                      <th>To</th>
                      <th>Departure</th>
                      <th>Arrival</th>
                      <th>&nbsp;</th>
                    </tr>
                    </thead>
                    <tbody>
                    {flights.map((flight, index) => (
                      <tr key={index}>
                        <td>
                          <Form.Check type="checkbox"/>
                        </td>
                        <td>
                          <span
                            className="ff-numerals text-dark">{flight.code}</span>
                        </td>
                        <td>
                          <span
                            className="ff-numerals text-dark">{flight.number}</span>
                        </td>
                        <td>
                          <span
                            className="ff-numerals text-dark">{flight.departureAirport.iata}</span>
                        </td>
                        <td>
                          <span
                            className="ff-numerals text-dark">{flight.arrivalAirport.iata}</span>
                        </td>
                        <td>
                          <span
                            className="text-gray-600">{getDateTimeFromIso(flight.departureTime)}</span>
                        </td>
                        <td>
                          <span
                            className="text-gray-600">{getDateTimeFromIso(flight.arrivalTime)}</span>
                        </td>
                        <td>
                          <div className="d-flex justify-content-end">
                            <Dropdown align="end" className="dropdown-profile">
                              <Dropdown.Toggle as={FlightActionsToggle} className="link-more">
                                <i className="ri-more-2-fill"></i>
                              </Dropdown.Toggle>
                              <Dropdown.Menu className="p-2">
                                <Dropdown.Item
                                  className="d-flex gap-2 align-items-center"
                                  onClick={() => {
                                    dispatch(deleteFlight({token, flightId: flight.id}));
                                  }}
                                >
                                  <i className="ri-delete-bin-7-fill"></i> Delete flight
                                </Dropdown.Item>
                              </Dropdown.Menu>
                            </Dropdown>
                          </div>
                        </td>
                      </tr>
                    ))}
                    </tbody>
                  </Table>
                ) : <></>}

                {!isPristine && !isFlightsLoading && !flights?.length ? (
                  <p className="mb-0">No flights</p>
                ) : <></>}

                {flights?.length > 20 ? (
                  <Pagination className="pagination-space pagination-circled mb-0 mt-4">
                    <Pagination.Item disabled><i className="ri-arrow-left-s-line"></i></Pagination.Item>
                    <Pagination.Item active>1</Pagination.Item>
                    <Pagination.Item>2</Pagination.Item>
                    <Pagination.Item>3</Pagination.Item>
                    <Pagination.Item><i className="ri-arrow-right-s-line"></i></Pagination.Item>
                  </Pagination>
                ) : (
                  <></>
                )}

              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Footer/>
      </div>

      <Modal
        show={showAddFlightModal}
        onHide={handleCloseAddFlightModal}
        centered={true}
        size={"lg"}
      >
        <Formik
          initialValues={{
            aircraft: '',
            airline: '',
            from: '',
            to: '',
            flightCode: '',
            flightNumber: '',
            departureTime: '',
            arrivalTime: '',
            crewMembers: []
          }}
          validationSchema={validationSchema}
          onSubmit={values => {
            dispatch(createFlight({token, formFields: values, callback: handleCloseAddFlightModal}));
          }}
        >
          {({values, touched, errors, setFieldValue}) => (
            <FormikForm>
              <Modal.Header closeButton>
                <Modal.Title>Create new flight</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Row>
                  <h5>Aircraft</h5>
                  <Col>
                    <Form.Group className="mb-3">
                      <Field name="aircraft">
                        {({
                            form: {touched, errors},
                          }) => (
                          <div>
                            <Typeahead
                              id="aircraft"
                              labelKey={(option: AircraftTypes) => `${option.manufacturer} ${option.model}`}
                              isLoading={isAircraftLoading}
                              isInvalid={touched.aircraft && !!errors.aircraft}
                              placeholder="Select aircraft"
                              onFocus={handleSearchAircraft}
                              onInputChange={handleSearchAircraft}
                              onChange={(selected: AircraftTypes[]) => {
                                if (selected.length > 0) {
                                  setFieldValue('aircraft', selected[0].id)
                                } else {
                                  setFieldValue('aircraft', '')
                                }
                              }}
                              options={aircraftSearchResults}
                              selected={aircraftSearchResults.filter(aircraft => aircraft.id === values.aircraft)}
                            />
                            <Form.Control.Feedback type="invalid">
                              <ErrorMessage name="aircraft" component="div" className="field-error"/>
                            </Form.Control.Feedback>
                          </div>
                        )}
                      </Field>
                      <Form.Control.Feedback type="invalid">
                        <ErrorMessage name="aircraft" component="div" className="field-error"/>
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <h5>Airline</h5>
                  <Col>
                    <Form.Group className="mb-3">
                      <Field name="airline">
                        {({
                            field,
                            form: {touched, errors},
                            meta
                          }) => (
                          <div>
                            <Typeahead
                              id="airline"
                              labelKey={(option: AirlineItemType) => `${option.iata} ${option.name}`}
                              isLoading={isAirlinesLoading}
                              isInvalid={touched.airline && !!errors.airline}
                              placeholder="Select airline"
                              onInputChange={handleSearchAirline}
                              onChange={(selected: AirlineItemType[]) => {
                                if (selected.length > 0) {
                                  setFieldValue('airline', selected[0].id)
                                } else {
                                  setFieldValue('airline', '')
                                }
                              }}
                              options={airlinesSearchResults}
                              selected={airlinesSearchResults.filter(airline => airline.id === values.airline)}
                            />
                            <Form.Control.Feedback type="invalid">
                              <ErrorMessage name="airline" component="div" className="field-error"/>
                            </Form.Control.Feedback>
                          </div>
                        )}
                      </Field>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <h5>Airports</h5>
                  <Col>
                    <Form.Group className="mb-3">
                      <Field name="from">
                        {({
                            form: {touched, errors},
                          }) => (
                          <div>
                            <Typeahead
                              id="from"
                              labelKey={(option: AirportItemTypes) => `${option.iata} ${option.name}`}
                              isLoading={isAirportsLoading}
                              isInvalid={touched.from && !!errors.from}
                              placeholder="From"
                              onInputChange={handleSearchAirports}
                              onChange={(selected: AirportItemTypes[]) => {
                                if (selected.length > 0) {
                                  setFieldValue('from', selected[0].id)
                                } else {
                                  setFieldValue('from', '')
                                }
                              }}
                              options={airportsSearchResults}
                              selected={values.from && airportsSearchResults.filter(airport => airport.id === values.from)}
                            />
                            <Form.Control.Feedback type="invalid">
                              <ErrorMessage name="from" component="div" className="field-error"/>
                            </Form.Control.Feedback>
                          </div>
                        )}
                      </Field>
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group className="mb-3">
                      <Field name="to">
                        {({
                            form: {touched, errors},
                          }) => (
                          <div>
                            <Typeahead
                              id="to"
                              labelKey={(option: AirportItemTypes) => `${option.iata} ${option.name}`}
                              isLoading={isAirportsLoading}
                              isInvalid={touched.to && !!errors.to}
                              placeholder="To"
                              onInputChange={handleSearchAirports}
                              onChange={(selected: AirportItemTypes[]) => {
                                if (selected.length > 0) {
                                  setFieldValue('to', selected[0].id)
                                } else {
                                  setFieldValue('to', '')
                                }
                              }}
                              options={airportsSearchResults}
                              selected={values.to && airportsSearchResults.filter(airport => airport.id === values.to)}
                            />
                            <Form.Control.Feedback type="invalid">
                              <ErrorMessage name="to" component="div" className="field-error"/>
                            </Form.Control.Feedback>
                          </div>
                        )}
                      </Field>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <h5>Flight details</h5>
                  <Col>
                    <Form.Group className="mb-3">
                      <Field isInvalid={touched.flightCode && !!errors.flightCode} as={Form.Control} name="flightCode"
                             type="text" placeholder="Flight code" onChange={(e) => {
                        setFieldValue('flightCode', e.target.value.toUpperCase());
                      }}/>
                      <Form.Control.Feedback type="invalid">
                        <ErrorMessage name="flightCode" component="div" className="field-error"/>
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group className="mb-3">
                      <Field isInvalid={touched.flightNumber && !!errors.flightNumber} as={Form.Control}
                             name="flightNumber" type="text" placeholder="Flight number" onChange={(e) => {
                        setFieldValue('flightNumber', e.target.value.toUpperCase());
                      }}/>
                      <Form.Control.Feedback type="invalid">
                        <ErrorMessage name="flightNumber" component="div" className="field-error"/>
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <h5>Date and time</h5>
                  <Col>
                    <Form.Group className="mb-3">
                      <DatePicker
                        placeholderText="Select departure time"
                        selected={values.departureTime}
                        onChange={(date) => setFieldValue('departureTime', date)}
                        showTimeSelect
                        timeFormat="HH:mm"
                        timeIntervals={15}
                        timeCaption="time"
                        dateFormat="d MMMM, yyyy HH:mm"
                        minDate={new Date()}
                        customInput={<Field isInvalid={touched.departureTime && !!errors.departureTime}
                                            as={Form.Control} name="departureTime"/>}
                      />
                      <Form.Control.Feedback type="invalid">
                        <ErrorMessage name="departureTime" component="div" className="field-error"/>
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group className="mb-3">
                      <DatePicker
                        disabled={!values.departureTime}
                        placeholderText="Select arrival time"
                        selected={values.arrivalTime}
                        onChange={(date) => setFieldValue('arrivalTime', date)}
                        showTimeSelect
                        timeFormat="HH:mm"
                        timeIntervals={15}
                        timeCaption="time"
                        dateFormat="d MMMM, yyyy HH:mm"
                        minDate={values.departureTime || new Date()}
                        minTime={new Date(new Date(values.departureTime).getTime() + 15 * 60000)}
                        maxTime={new Date('2100-12-31T23:59:59')}
                        customInput={<Field isInvalid={touched.arrivalTime && !!errors.arrivalTime} as={Form.Control}
                                            name="arrivalTime"/>}
                      />
                      <Form.Control.Feedback type="invalid">
                        <ErrorMessage name="arrivalTime" component="div" className="field-error"/>
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <h5>Crew member access</h5>
                  <Col>
                    <Form.Group className="mb-3">
                      <Field name="crewMembers">
                        {({
                            form: {touched, errors},
                          }) => (
                          <div>
                            <Typeahead
                              id="crewMembers"
                              labelKey={(option: UserTypes) => `${option.firstName} ${option.lastName}`}
                              isInvalid={touched.crewMembers && !!errors.crewMembers}
                              multiple
                              placeholder="Select crew members"
                              onChange={(selected) => {
                                if (selected.length > 0) {
                                  setFieldValue('crewMembers', selected.map((item: UserTypes) => item.id))
                                } else {
                                  setFieldValue('crewMembers', [])
                                }
                              }}
                              options={crewsUsersList}
                              selected={values.crewMembers && crewsUsersList.filter((user) => values.crewMembers.includes(user.id))}
                            />
                            <Form.Control.Feedback type="invalid">
                              <ErrorMessage name="crewMembers" component="div" className="field-error"/>
                            </Form.Control.Feedback>
                          </div>
                        )}
                      </Field>
                    </Form.Group>
                  </Col>
                </Row>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="info" onClick={handleCloseAddFlightModal}>
                  Close
                </Button>
                <Button variant="primary" type="submit" disabled={isCreatingFlight}>
                  {isCreatingFlight ? (
                    <>
                      <Spinner animation="border" size="sm" variant="light" className="me-2"/> Creating...
                    </>
                  ) : (
                    "Create flight"
                  )}
                </Button>
              </Modal.Footer>
            </FormikForm>
          )}
        </Formik>
      </Modal>
    </React.Fragment>
  );
};

export default Flights;

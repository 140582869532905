import {combineReducers} from "@reduxjs/toolkit";
import usersSlice from "./usersSlice"
import userDetailsSlice from "./userDetailsSlice";
import errorSlice from "./errorSlice";
import authUserSlice from "./authUserSlice";
import aircraftSlice from "./aircraftSlice";
import airlinesSlice from "./airlinesSlice";
import airportsSlice from "./airportsSlice";
import flightsSlice from "./flightsSlice";
import bookingsSlice from "./bookingsSlice";
import crewsSlice from "./crewsSlice";

const rootReducer = {
  authUser: authUserSlice,
  users: usersSlice,
  userDetails: userDetailsSlice,
  aircraft: aircraftSlice,
  airlines: airlinesSlice,
  airports: airportsSlice,
  crews: crewsSlice,
  flights: flightsSlice,
  bookings: bookingsSlice,
  errors: errorSlice
}

export default combineReducers(rootReducer);

export const ROUTES = {
  HOME: "/",
  DASHBOARD: "dashboard",
  USERS: "dashboard/users",
  USER_PROFILE: "dashboard/users/:userId",
  CUSTOMERS: "dashboard/customers",
  CUSTOMER_PROFILE: "dashboard/customers/:customerId",
  DEMO_BOOKINGS: "dashboard/demo-bookings",
  FLIGHTS: "dashboard/flights",
}

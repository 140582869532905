import axios from "axios";
import {UserDetailsTypes, UserTypes} from "../types/Users/Users.types";

const instanceXchk = axios.create({
  baseURL: `${process.env.REACT_APP_API_BASE_URL}`,
  headers: {
    'Content-Type': 'application/json',
  },
});

export type ApiResponse = {
  error?: null | string
};

export const handleRequest = async <T>(
  method: string,
  endpoint: string,
  token: string,
  data?: any,
): Promise<T & ApiResponse> => {
  let responseData;

  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
  };

  try {
    let response;
    switch (method.toUpperCase()) {
      case "GET":
        response = await instanceXchk.get(`${endpoint}/`, {
          params: data ? data : {},
          headers,
        });
        break;
      case "POST":
        response = await instanceXchk.post(`/${endpoint}/`, data, { headers });
        break;
      case "PUT":
        response = await instanceXchk.put(`/${endpoint}/`, data, { headers });
        break;
      case "PATCH":
        response = await instanceXchk.patch(`/${endpoint}/`, data, { headers });
        break;
      case "DELETE":
        response = await instanceXchk.delete(`${endpoint}/`, {
          params: data ? data : {},
          headers,
        });
        break;
      default:
        throw new Error("Invalid HTTP method");
    }

    responseData = response.data;
  } catch (error) {
    if (error instanceof Error && error.message) {
      responseData = {
        error: error.message,
      };
    } else {
      console.log(error);
    }
  }
  return responseData;
};


import {createSlice} from '@reduxjs/toolkit';
import {assignFlightToCrewMember, loadCrewMembersList} from "../thunk/crewsThunks";
import {loadUsersList} from "../thunk/usersThunks";

const initialState = {
  isLoading: false,
  isAssigning: false,
  crewsUsersList: [],
  error: null,
}

const crewsSlice = createSlice({
  name: 'crews',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(loadCrewMembersList.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(loadCrewMembersList.fulfilled, (state, action) => {
        state.isLoading = false;
        state.crewsUsersList = action.payload.length ? action.payload : initialState.crewsUsersList;
      })
      .addCase(loadCrewMembersList.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });

    builder
      .addCase(assignFlightToCrewMember.pending, (state) => {
        state.isAssigning = true;
        state.error = null;
      })
      .addCase(assignFlightToCrewMember.fulfilled, (state, action) => {
        state.isAssigning = false;
      })
      .addCase(assignFlightToCrewMember.rejected, (state, action) => {
        state.isAssigning = false;
        state.error = action.payload;
      });
  },
});

export default crewsSlice.reducer;

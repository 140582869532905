import {createAsyncThunk} from "@reduxjs/toolkit";
import {setGlobalError} from "../reducers/errorSlice";
import AIRPORTS_API from "../../api/AIRPORTS_API";
import {AirportsSearchResponseType} from "../../types/Airports/Airports.types";

export const getAirportsSearchResults = createAsyncThunk<AirportsSearchResponseType, { token: string, searchTerm: string }>("airports/searchResults", async ({token, searchTerm}, { rejectWithValue, dispatch}) => {
  try {
    return await AIRPORTS_API.getAirportsSearchResults(token, searchTerm);
  } catch (error) {
    dispatch(setGlobalError(error.message));
    return rejectWithValue(error.message);
  }
});

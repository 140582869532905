import React from 'react';
import {BrowserRouter, Route, Routes} from "react-router-dom";
import Main from "../layouts/Main";
import Signin from "../pages/SignIn/Signin";
import protectedRoutes from "./ProtectedRoutes";
import publicRoutes from "./PublicRoutes";
import NotFound from "../pages/NotFound/NotFound";
import {useAuth} from "react-oidc-context";

const AppRouter = () => {
  const auth = useAuth();

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={auth.isAuthenticated ? <Main/> : <Signin/>}>
          {protectedRoutes.map((route, index) => {
            return (
              <Route
                path={route.path}
                element={<route.element />}
                key={index}
              />
            )
          })}
        </Route>
        {publicRoutes.map((route, index) => {
          return (
            <Route
              path={route.path}
              element={<route.element />}
              key={index}
            />
          )
        })}
        <Route path="*" element={<NotFound/>}/>
      </Routes>
    </BrowserRouter>
  );
};

export default AppRouter;

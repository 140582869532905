import {createSlice} from '@reduxjs/toolkit';
import {getAirlinesSearchResults} from "../thunk/airlinesThunks";
import {getAirportsSearchResults} from "../thunk/airportsThunks";

const initialState = {
  isLoading: false,
  airportsSearchResults: [],
  error: null,
}

const airportsSlice = createSlice({
  name: 'airports',
  initialState,
  reducers: {
    resetAirportsSearch: (state) => {
      state.airportsSearchResults = initialState.airportsSearchResults;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAirportsSearchResults.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(getAirportsSearchResults.fulfilled, (state, action) => {
        state.isLoading = false;
        const newItems = action.payload?.items?.length ? action.payload.items : [];
        const uniqueItems = newItems.filter(newItem => !state.airportsSearchResults.some(item => item.id === newItem.id));
        state.airportsSearchResults = [...state.airportsSearchResults, ...uniqueItems];
      })
      .addCase(getAirportsSearchResults.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });
  },
});

export const { resetAirportsSearch } = airportsSlice.actions;

export default airportsSlice.reducer;

import {ROUTES} from "../constants";
import {RouteTypes} from "./Routes.types";

import Dashboard from "../pages/dashboard/Dashboard";
import UsersList from "../pages/dashboard/Users/UsersList";
import UserDetails from "../pages/dashboard/Users/UserDetails";
import Customers from "../pages/dashboard/Customers/Customers";
import CustomerDetails from "../pages/dashboard/Customers/CustomerDetails";
import DemoBookings from "../pages/dashboard/DemoBookings/DemoBookings";
import Flights from "../pages/dashboard/Flights/Flights";

const protectedRoutes: RouteTypes[] = [
  {path: ROUTES.HOME, element: Dashboard},
  {path: ROUTES.DASHBOARD, element: Dashboard},
  {path: ROUTES.USERS, element: UsersList},
  {path: ROUTES.USER_PROFILE, element: UserDetails},
  {path: ROUTES.CUSTOMERS, element: Customers},
  {path: ROUTES.CUSTOMER_PROFILE, element: CustomerDetails},
  {path: ROUTES.FLIGHTS, element: Flights},
  {path: ROUTES.DEMO_BOOKINGS, element: DemoBookings},
]

export default protectedRoutes;

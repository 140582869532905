import React, {useState} from 'react';
import Header from "../../../layouts/Header";
import {Link} from "react-router-dom";
import {ROUTES} from "../../../constants";
import {Button, Card, Col, Form, Modal, Pagination, Row, Spinner, Table} from "react-bootstrap";
import UserAvatar from "../../../assets/img/user.jpg";
import Avatar from 'react-avatar';
import Footer from "../../../layouts/Footer";

const Customers = () => {
  const [showAddCustomerModal, setShowAddCustomerModal] = useState(false);

  const handleCloseAddCustomerModal = () => setShowAddCustomerModal(false);
  const handleShowAddCustomerModal = () => setShowAddCustomerModal(true);

  return (
    <React.Fragment>
      <Header/>
      <div className="main main-app p-3 p-lg-4">
        <div className="d-md-flex align-items-center justify-content-between mb-4">
          <div className="w-100">
            <ol className="breadcrumb fs-sm mb-1">
              <li className="breadcrumb-item"><Link to={`/${ROUTES.DASHBOARD}`}>Dashboard</Link></li>
              <li className="breadcrumb-item active" aria-current="page">Customers</li>
            </ol>
            <Row className="g-3">
              <Col md={6}>
                <h4 className="main-title mb-0">Customers</h4>
              </Col>
              <Col md={6} className="d-flex justify-content-end">
                <div className="btn-container align-self-start">
                  <Button
                    variant="outline-primary"
                    className="d-flex align-items-center gap-1"
                    onClick={handleShowAddCustomerModal}
                  >
                    <i className="ri-add-line"></i> Add customer
                  </Button>
                </div>
              </Col>
            </Row>

          </div>
        </div>
        <Row className="g-3">
          <Col xs="12">
            <Row className="g-2 g-xxl-3 mb-5">
              <Col sm="6" md="4">
                <Card className="card-people">
                  <Card.Body>
                    <Link to=""><Avatar name="Lufthansa AG" round={true} /></Link>
                    <h6 className="mt-3"><Link to="">Lufthansa AG</Link></h6>
                    <p>abbreviation</p>
                    <div className="mutual-badge mb-3">
                      <small className="text-success">Active</small>
                    </div>
                    <div className="d-grid">
                      <Link to="1">
                        <Button variant="outline-primary" className="w-100">View details</Button>
                      </Link>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
              <Col sm="6" md="4">
                <Card className="card-people">
                  <Card.Body>
                    <Link to="">
                      <Link to=""><Avatar name="Swiss AG" round={true} /></Link>
                    </Link>
                    <h6 className="mt-3"><Link to="">Swiss AG</Link></h6>
                    <p>abbreviation</p>
                    <div className="mutual-badge mb-3">
                      <small className="text-success">Active</small>
                    </div>
                    <div className="d-grid">
                      <Link to="1">
                        <Button variant="outline-primary" className="w-100">View details</Button>
                      </Link>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
              <Col sm="6" md="4">
                <Card className="card-people">
                  <Card.Body>
                    <Link to=""><Avatar name="Scandinavian Airlines" round={true} /></Link>
                    <h6 className="mt-3"><Link to="">Scandinavian Airlines</Link></h6>
                    <p>abbreviation</p>
                    <div className="mutual-badge mb-3">
                      <small className="text-danger">Inactive</small>
                    </div>
                    <div className="d-grid">
                      <Link to="1">
                        <Button variant="outline-primary" className="w-100">View details</Button>
                      </Link>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Col>
        </Row>
        <Footer/>
      </div>

      <Modal
        show={showAddCustomerModal}
        onHide={handleCloseAddCustomerModal}
        centered={true}
        size={"lg"}
      >
        <Modal.Header closeButton>
          <Modal.Title>Add new customer</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3">
              <Form.Control type="text" placeholder="Title" />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Control type="text" placeholder="Abbreviation" />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Control as="textarea" rows={3} placeholder="Notes" />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Check type="switch" id="active-inactive-switch" label="Active" />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="info" onClick={handleCloseAddCustomerModal}>
            Close
          </Button>
          <Button variant="primary" onClick={handleCloseAddCustomerModal}>
            Add customer
          </Button>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
};

export default Customers;

import React, {useCallback, useEffect, useState} from 'react';
import Header from "../../../layouts/Header";
import {Link} from "react-router-dom";
import {ROUTES} from "../../../constants";
import {Button, Card, Col, Form, FormControl, Modal, Pagination, Row, Spinner, Table} from "react-bootstrap";
import Footer from "../../../layouts/Footer";
import Dropdown from "react-bootstrap/Dropdown";
import useAuthUser from "../../../hooks/auth/useAuthUser";
import {useAppDispatch, useAppSelector} from "../../../hooks/hooks";
import {RootState} from "../../../redux/store";
import {createBooking, deleteBooking, getBookingList} from "../../../redux/thunk/bookingsThunks";
import {ErrorMessage, Field, Form as FormikForm, Formik} from 'formik';
import * as Yup from "yup";
import {getFlightList} from "../../../redux/thunk/flightsThunks";
import {getDateTimeFromIso} from "../../../helpers";
import {Typeahead} from "react-bootstrap-typeahead";
import {FlightTypes} from "../../../types/Flight/Flight.types";
import {NewBookingFormFieldsTypes} from "../../../types/Booking/Booking.types";

const validationSchema = Yup.object({
  bookingId: Yup.string().required('Required'),
  flightId: Yup.string().required('Required'),
  firstName: Yup.string().required('Required'),
  lastName: Yup.string().required('Required'),
  seatClass: Yup.string().required('Required'),
  seatNumber: Yup.string().required('Required'),
});

const DemoBookings = () => {
  const {token} = useAuthUser();
  const dispatch = useAppDispatch();
  const [isPristine, setIsPristine] = useState(true);
  const {
    isLoading: isBookingsLoading,
    isCreating: isCreatingBooking,
    bookings
  } = useAppSelector((state: RootState) => state.bookings);
  const {
    isLoading: isFlightsLoading,
    flights
  } = useAppSelector((state: RootState) => state.flights);

  const [showAddBookingModal, setShowAddBookingModal] = useState(false);

  const handleCloseAddBookingModal = () => setShowAddBookingModal(false);
  const handleShowAddBookingModal = () => setShowAddBookingModal(true);


  const BookingActionsToggle = React.forwardRef(({children, onClick}: {
    children: React.ReactNode,
    onClick: (e) => void;
  }, ref: React.Ref<HTMLAnchorElement>) => (
    <Link
      to=""
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
      className="dropdown-link"
    >
      {children}
    </Link>
  ));

  const handleUpdateFlights = () => {
    dispatch(getFlightList({token}));
  }

  const handleUpdateBookings = () => {
    dispatch(getBookingList({token}));

  }

  const handleSubmitAddBookingForm = useCallback((values: NewBookingFormFieldsTypes) => {
    const flight = flights.find((flight) => flight.id === values.flightId);
    if (flight) {
      dispatch(createBooking({token, formFields: values, flightData: flight, callback: handleCloseAddBookingModal}));
    }
  }, [token, flights]);

  useEffect(() => {
    handleUpdateFlights();
    handleUpdateBookings();
    setIsPristine(false);
  }, []);
  return (
    <React.Fragment>
      <Header/>
      <div className="main main-app p-3 p-lg-4">
        <div className="d-md-flex align-items-center justify-content-between mb-4">
          <div className="w-100">
            <ol className="breadcrumb fs-sm mb-1">
              <li className="breadcrumb-item"><Link to={`/${ROUTES.DASHBOARD}`}>Dashboard</Link></li>
              <li className="breadcrumb-item active" aria-current="page">Demo bookings</li>
            </ol>
            <Row className="g-3">
              <Col md={6}>
                <h4 className="main-title mb-0">Booking list</h4>
              </Col>
              <Col md={6} className="d-flex justify-content-end">
                <div className="btn-container align-self-start">
                  <Button
                    variant="outline-primary"
                    className="d-flex align-items-center gap-1"
                    onClick={handleShowAddBookingModal}
                  >
                    <i className="ri-add-line"></i> Add booking
                  </Button>
                </div>
              </Col>
            </Row>

          </div>
        </div>
        <Row className="g-3">
          <Col xs="12">
            <Card className="card-one">
              <Card.Body>
                {isBookingsLoading && !bookings?.length ? (
                  <Spinner animation="border" variant="primary"/>
                ) : <></>}

                {!isPristine && bookings?.length ? (
                  <Table className="table-users mb-0" responsive>
                    <thead>
                    <tr>
                      <th>
                        <Form.Check type="checkbox"/>
                      </th>
                      <th>ID</th>
                      <th>Reference</th>
                      <th>Code</th>
                      <th>&nbsp;</th>
                    </tr>
                    </thead>
                    <tbody>
                    {bookings.map((booking, index) => (
                      <tr key={index}>
                        <td>
                          <Form.Check type="checkbox"/>
                        </td>
                        <td><span
                          className="ff-numerals text-dark">{booking.id}</span>
                        </td>
                        <td><span
                          className="ff-numerals text-dark">{booking.reference}</span>
                        </td>
                        <td><span
                          className="ff-numerals text-dark">{booking.code}</span>
                        </td>
                        <td>
                          <div className="d-flex justify-content-end">
                            <Dropdown align="end" className="dropdown-profile">
                              <Dropdown.Toggle as={BookingActionsToggle} className="link-more">
                                <i className="ri-more-2-fill"></i>
                              </Dropdown.Toggle>
                              <Dropdown.Menu className="p-2">
                                <Dropdown.Item
                                  className="d-flex gap-2 align-items-center"
                                  onClick={() => {
                                    dispatch(deleteBooking({token, bookingId: booking.id}));
                                  }}
                                >
                                  <i className="ri-delete-bin-7-fill"></i> Delete booking
                                </Dropdown.Item>
                              </Dropdown.Menu>
                            </Dropdown>
                          </div>
                        </td>
                      </tr>
                    ))}
                    </tbody>
                  </Table>
                ) : <></>}

                {!isPristine && !isBookingsLoading && !bookings?.length ? (
                  <p className="mb-0">No bookings</p>
                ) : <></>}

                {bookings?.length > 20 ? (
                  <Pagination className="pagination-space pagination-circled mb-0 mt-4">
                    <Pagination.Item disabled><i className="ri-arrow-left-s-line"></i></Pagination.Item>
                    <Pagination.Item active>1</Pagination.Item>
                    <Pagination.Item>2</Pagination.Item>
                    <Pagination.Item>3</Pagination.Item>
                    <Pagination.Item><i className="ri-arrow-right-s-line"></i></Pagination.Item>
                  </Pagination>
                ) : (
                  <></>
                )}

              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Footer/>
      </div>

      <Modal
        show={showAddBookingModal}
        onHide={handleCloseAddBookingModal}
        centered={true}
        size={"lg"}
      >
        <Formik
          initialValues={{
            bookingId: '',
            flightId: '',
            firstName: '',
            lastName: '',
            seatClass: '',
            seatNumber: '',
          }}
          validationSchema={validationSchema}
          onSubmit={handleSubmitAddBookingForm}
        >
          {({values, touched, errors, setFieldValue}) => (
            <FormikForm>
              <Modal.Header closeButton>
                <Modal.Title>Add new booking</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Row>
                  <h5>Booking details</h5>
                  <Col>
                    <Form.Group className="mb-3">
                      <Field
                        isInvalid={touched.bookingId && !!errors.bookingId}
                        as={FormControl}
                        name="bookingId"
                        placeholder="Booking Id"
                        onChange={(e) => {
                          setFieldValue('bookingId', e.target.value.toUpperCase());
                        }}
                      />
                      <Form.Control.Feedback type="invalid">
                        <ErrorMessage name="bookingId" component="div" className="field-error"/>
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <h5>Flight</h5>
                  <Col>
                    <Form.Group className="mb-3">
                      <Field name="flightId">
                        {({
                            form: {touched, errors},
                          }) => (
                          <div>
                            <Typeahead
                              id="flightId"
                              labelKey={(option: FlightTypes) => `${option.code} ${option.number} / ${getDateTimeFromIso(option.departureTime)} - ${getDateTimeFromIso(option.arrivalTime)}`}
                              isInvalid={touched.flightId && !!errors.flightId}
                              placeholder="Select flight"
                              onChange={(selected: FlightTypes[]) => {
                                if (selected.length > 0) {
                                  setFieldValue('flightId', selected[0].id)
                                } else {
                                  setFieldValue('flightId', '')
                                }
                              }}
                              options={flights}
                              selected={values.flightId && flights.filter((flight) => values.flightId === flight.id)}
                            />
                            <Form.Control.Feedback type="invalid">
                              <ErrorMessage name="flightId" component="div" className="field-error"/>
                            </Form.Control.Feedback>
                          </div>
                        )}
                      </Field>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <h5>Seat</h5>
                  <Col>
                    <Form.Group className="mb-3">
                      <Field name="seatClass">
                        {({
                            form: {touched, errors},
                          }) => (
                          <div>
                            <Typeahead
                              id="seatClass"
                              labelKey="label"
                              isInvalid={touched.seatClass && !!errors.seatClass}
                              placeholder="Select seat class"
                              onChange={(selected: { label: string }[]) => {
                                if (selected.length > 0) {
                                  setFieldValue('seatClass', selected[0].label)
                                } else {
                                  setFieldValue('seatClass', '')
                                }
                              }}
                              options={[
                                {label: 'Economy'},
                                {label: 'Business'},
                                {label: 'First'}
                              ]}
                              selected={values.seatClass ? [{label: values.seatClass}] : []}
                            />
                            <Form.Control.Feedback type="invalid">
                              <ErrorMessage name="seatClass" component="div" className="field-error"/>
                            </Form.Control.Feedback>
                          </div>
                        )}
                      </Field>
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group className="mb-3">
                      <Field
                        isInvalid={touched.seatNumber && !!errors.seatNumber}
                        as={FormControl}
                        name="seatNumber"
                        placeholder="Seat number"
                        onChange={(e) => {
                          setFieldValue('seatNumber', e.target.value.toUpperCase());
                        }}
                      />
                      <Form.Control.Feedback type="invalid">
                        <ErrorMessage name="seatNumber" component="div" className="field-error"/>
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <h5>Passengers</h5>
                  <Col>
                    <Form.Group className="mb-3">
                      <Field
                        isInvalid={touched.firstName && !!errors.firstName}
                        as={FormControl}
                        name="firstName"
                        placeholder="First name"
                        onChange={(e) => {
                          setFieldValue('firstName', e.target.value.toUpperCase());
                        }}
                      />
                      <Form.Control.Feedback type="invalid">
                        <ErrorMessage name="firstName" component="div" className="field-error"/>
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group className="mb-3">
                      <Field
                        isInvalid={touched.lastName && !!errors.lastName}
                        as={FormControl}
                        name="lastName"
                        placeholder="Last name"
                        onChange={(e) => {
                          setFieldValue('lastName', e.target.value.toUpperCase());
                        }}
                      />
                      <Form.Control.Feedback type="invalid">
                        <ErrorMessage name="lastName" component="div" className="field-error"/>
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Row>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="info" onClick={handleCloseAddBookingModal}>
                  Close
                </Button>
                <Button variant="primary" type="submit" disabled={isCreatingBooking}>
                  {isCreatingBooking ? (
                    <>
                      <Spinner animation="border" size="sm" variant="light" className="me-2"/> Creating...
                    </>
                  ) : (
                    "Add booking"
                  )}
                </Button>
              </Modal.Footer>
            </FormikForm>
          )}
        </Formik>
      </Modal>
    </React.Fragment>
  );
};

export default DemoBookings;

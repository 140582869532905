import styled from "styled-components";

export const StyledLogo = styled.div`
  h3 {
    color: var(--bs-primary);
    font-weight: 900;
    font-family: "Microgramma D", sans-serif;

    span {
      color: var(--bs-pink);
    }
  }
`

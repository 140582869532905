export const getDateTimeFromUnix = (timestamp: number) => {
  if (timestamp) {
    const date = new Date(+timestamp);

    const year = date.getFullYear();
    const month = ('0' + (date.getMonth() + 1)).slice(-2);
    const day = ('0' + date.getDate()).slice(-2);
    const hours = ('0' + date.getHours()).slice(-2);
    const minutes = ('0' + date.getMinutes()).slice(-2);
    const seconds = ('0' + date.getSeconds()).slice(-2);

    return `${day}.${month}.${year}, ${hours}:${minutes}:${seconds}`;
  }
  return "";
}

export const getDateTimeFromIso = (timestamp: string) => {
  if (timestamp) {
    const date = new Date(timestamp);

    const year = date.getFullYear();
    const month = ('0' + (date.getMonth() + 1)).slice(-2);
    const day = ('0' + date.getDate()).slice(-2);
    const hours = ('0' + date.getHours()).slice(-2);
    const minutes = ('0' + date.getMinutes()).slice(-2);
    const seconds = ('0' + date.getSeconds()).slice(-2);

    return `${day}.${month}.${year}, ${hours}:${minutes}`;
  }
  return "";
}

export const getISODateTime = (dateString: string): string => {
  const date = new Date(dateString);
  return date.toISOString();
}




import NotFound from "../pages/NotFound/NotFound";
import Signin from "../pages/SignIn/Signin";
import {RouteTypes} from "./Routes.types";


const publicRoutes: RouteTypes[] = [
  {path: "login", element: Signin},
  {path: "pages/error-404", element: NotFound}
];

export default publicRoutes;

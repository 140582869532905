import { createSlice } from '@reduxjs/toolkit';
import {loadUsersList} from "../thunk/usersThunks";

const initialState = {
  isLoading: false,
  usersList: [],
  error: null,
}

const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(loadUsersList.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(loadUsersList.fulfilled, (state, action) => {
        state.isLoading = false;
        state.usersList = action.payload.length ? action.payload : [];
      })
      .addCase(loadUsersList.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });
  },
});

export default usersSlice.reducer;

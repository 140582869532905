import { createSlice } from '@reduxjs/toolkit';

interface InitialStateTypes {
  globalError: string | null
}

const initialState: InitialStateTypes = {
  globalError: null
}

const errorSlice = createSlice({
  name: 'errors',
  initialState,
  reducers: {
    setGlobalError: (state, action) => {
      state.globalError = action.payload;
    },
  },
});

export const { setGlobalError } = errorSlice.actions;
export default errorSlice.reducer;

import {handleRequest} from "./API";
import {AirportsSearchResponseType} from "../types/Airports/Airports.types";

const AIRPORTS_API = {
  getAirportsSearchResults: async (token: string, searchTerm: string) => {
    return await handleRequest<AirportsSearchResponseType>(
      "get",
      `airports`,
      token,
      {searchTerm}
    );
  }
}

export default AIRPORTS_API;
